<template>
  <div class="main-container">
    <header class="header">
      <h4 class="title">UX Engineering Manager, Product Design</h4>
      <p class="type">REMOTE / PERMANENT</p>
    </header>

    <section class="apply">
      <div class="apply__container">
        <p class="heading">SUBMIT YOUR APPLICATION</p>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Resume/CV <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="file" name="resume" id="resume" />
            <label for="resume" class="cover-file">
              <img src="@/assets/svg/attachment-icon.svg" alt="" class="icon" />
              <p class="text">ATTACH RESUME / CV</p>
            </label>
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Cover letter <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="file" name="c-letter" id="c-letter" />
            <label for="c-letter" class="cover-file">
              <img src="@/assets/svg/attachment-icon.svg" alt="" class="icon" />
              <p class="text">ATTACH COVER LETTER</p>
            </label>
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">First name <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Last name <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Email <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Phone <sup>*</sup></p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Current Company</p>
            <p class="label--sub">Skip if not currently employed!</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Current job title</p>
            <p class="label--sub">Skip if doesn't apply!</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <p class="heading">LINKS</p>
        <div class="input-container">
          <div class="left-column">
            <p class="label">LinkedIn URL</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">LinkedIn URL</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">GitHub URL</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Portfolio URL</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <div class="input-container">
          <div class="left-column">
            <p class="label">Other website</p>
          </div>
          <div class="right-column">
            <input type="text" class="form-input" />
          </div>
        </div>
        <p class="heading">DEMOGRAPHIC SURVEY</p>
        <div class="input-container">
          <div class="left-column">
            <p class="label">What your location?</p>
          </div>
          <div class="right-column">
            <b-form-select
              v-model="selected"
              :options="options"
              class="form-select"
            ></b-form-select>
          </div>
        </div>
        <footer class="footer">
          <button class="btn btn--primary">SUBMIT APPLICATION</button>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Apply",
  data() {
    return {
      options: [
        { value: null, text: "Please select an option" },
        { value: "lagos", text: "Lagos" },
        { value: "abuja", text: "Abuja" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./apply.component.scss";
</style>
